import Player from '@vimeo/player';

export function getWindowHeight() {
	return ( window.innerHeight || document.documentElement.clientHeight );
}

export function getWindowWidth() {
	return ( window.innerWidth || document.documentElement.clientWidth );
}

export function getQueryString( query_object ) {
	const query_pieces = [];
	for ( const key in query_object ) {
		if ( !query_object.hasOwnProperty( key ) ) {
			continue;
		}
		if ( query_object[key] instanceof Array ) {
			query_pieces.push('&' + key.replace( '[]', '' ) + '='  );
			query_pieces.push(( query_object[key][0] ? query_object[key][0] : '' ) );
			for ( let i = 1; i < query_object[key].length; i++ ) {
				query_pieces.push(( query_object[key][i] ? '+' + query_object[key][i] : '' ) );
			}
			continue;
		}
	}

	if ( query_pieces.length === 0 ) {
		return '';
	}

	return '?' + query_pieces.join('');
}

export function getQueryObject( query_string ) {
	const query_object = {};
	const pairs = ( query_string[0] === '?' ? query_string.substr( 1 ) : query_string ).split( '&' );
	for ( let i = 0; i < pairs.length; i++ ) {
		const pair = pairs[i].split( '=' );
		if (pair !== '' ) {
			let key = decodeURIComponent( pair[0] );
			const value = decodeURIComponent( pair[1] || '' );
			if ( !( query_object[key] instanceof Array ) ) {
				query_object[key] = [];
			}
			query_object[key].push( value );
			continue;
		}
	}
	return query_object;
}

export const buildVimeoPlayers = ( videos ) => {
	videos.forEach( video => {
		const player_container = video.querySelector( '.video__player' );
		if ( !player_container ) {
			return;
		}
		const video_id = player_container.dataset.videoId;

		// Init Vimeo SDK
		const player = new Player( player_container, {
			id: video_id,
			width: 1920,
			height: 1080,
			dnt: 1
		} );

		if ( video.querySelector( '.video__placeholder' ) ||  video.querySelector( '.video__play-button' ) ) {
			// Preload video so it is ready on click to minimise the delay
			player.setVolume( 0 );
			player.play().then( () => {
				player.pause().then( () => {
					player.setVolume( 1 );
				} );
			} );

			video.onclick = () => {
				player.play().then( () => {
					video.classList.add( 'video--started' );
				} );
			};
		}
	} );
};

export const buildYoutubePlayers = ( videos ) => {
	// Load API script
	const api_script = document.querySelector( '#youtube-script' );
	if ( !api_script ) {
		const script = document.createElement( 'script' );
		script.src = 'https://www.youtube.com/iframe_api';
		script.id = 'youtube-script';

		const first_script_tag = document.getElementsByTagName( 'script' )[0];
		first_script_tag.parentNode.insertBefore( script, first_script_tag );
	}

	// Create players
	const players = [];
	window.onYouTubeIframeAPIReady = () => {
		videos.forEach( ( video, i ) => {
			const player_container = video.querySelector( '.video__player' );
			if ( !player_container ) {
				return;
			}
			const video_id = player_container.dataset.videoId;

			players[i] = new YT.Player( player_container, {
				width: '1920',
				height: '1080',
				videoId: video_id,
				playerVars: {
					'playsinline': 1
				}
			} );

			video.onclick = ( e ) => {
				if ( e.target.classList.contains( 'video__placeholder' ) ||  e.target.classList.contains( 'video__play-button' ) ) {
					players[i].playVideo();
					video.classList.add( 'video--started' );
				}
			};
		} );
	};
};